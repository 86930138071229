.card-aly {
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #fff;
}

.bg-title-aly {
    background-color: #e9edf1;
    padding: 8px;
}

.title-aly {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP , NotoSansCJKjp-Bold,sans-serif;
    font-weight: 700;
    font-style: normal;
}

.text-title {
    font-family: Noto Sans JP , NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: #091e42;
}

@media screen and (min-width: 992px){
    .border-analysis {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.dot-icon {
    font-size: 12px;
    color: rgba(197, 208, 222, 0.9);
}

.list-style-analysis {
    list-style: none;
    padding-inline-start: 0;
}

.list-style-analysis-2 {
    list-style: none;
    padding-inline-start: 1.2rem;
    border-left: dashed 1px #dfe2e5;
    margin-left: 5px;
}

.list-li {
    line-height: 2.3;
}

.text-list-day {
    color: #7f8fa4;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 5px;
}

.react-calendar-heatmap text {
    font-size: 5px;
    fill: #aaa;
}

.react-calendar-heatmap .color-empty { fill : #ebedf0}

.react-calendar-heatmap .color-null { fill : #ebedf0}

.react-calendar-heatmap .color-scale-less { fill: #58CCED; }

.react-calendar-heatmap .color-scale-normal { fill: #4d80e4; }

.react-calendar-heatmap .color-scale-normal { fill: #3895D3; }

.react-calendar-heatmap .color-scale-full-fill { fill: #1261A0; }

.react-calendar-heatmap .react-calendar-heatmap-weekday-labels {
    transform: translate(10px, 10px);
}

.react-calendar-heatmap .react-calendar-heatmap-month-labels {
    transform: translate(40px, 0px);
}

.date-chart-search .form-control {
    background-color: #fff;
}

@media only screen and (max-width: 768px) {
    .chart-smaller {
        max-width: 50% !important;
    }

    .chart-larger {
        max-width: 100% !important;
    }

}

background-question.record-editor .description {
    height: 144px;
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: #e9edf1;
}
#create-record .background-question {
    background-color: #e7f9f5;
    width: 100%;
    padding: 8px;
}

#create-record .content-heading-question,
#create-record .content-name-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    font-size: 16px;
    color: #0a1d42;
    line-height: 1.63;
    padding-top: 8px;
    display: block;
}

#create-record .question-number {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}



.background-question-review {
    width: 100%;
    padding: 8px;
}

.info-question {
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: #e9edf1;
    padding: 12px;
}

.survey {
    /*background-color: #eef3f6;*/
}

.survey .status-survey {
    background-color: #eef3f6;
    height: 68px;
    padding: 16px 8px;
}

.survey .status-survey button {
    padding: 5px 18px;
}

@media only screen and (max-height: 400px) {
    .action-record {
        display: none !important;
    }
}

.action-record {
    position: fixed;
    padding: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 998;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 0 5px rgba(0,0,0,0.08);
}

.survey .action-record button {
    border-radius: 0;
    width: 50%;
    padding: 8px 16px;
}

.survey .action-record button:disabled.btn.btn-kiroku-primary {
    border-radius: 0;
    width: 50%;
    text-transform: none;
    background-color: #dfe1e5 !important;
    opacity: .3;
    padding: 8px 16px;
}

.text-tranf {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 14px;
    line-height: 1.5;
}

.status-survey-content {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    color: #6c798f;
}

.yes-check {
    padding:0 0.5rem 0.5rem 0.5rem;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    border: solid 1px #ced0da;
    border-bottom: 0;
}

.no-check {
    padding:0 0.5rem 0.5rem 0.5rem;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    border: solid 1px #ced0da;
}

.answer-question .custom-control-label::before {
    border: solid 1px #cecdda;
}

.answer-question>textarea:active,
.answer-question>textarea:focus {
    outline: none !important;
    border:1px solid #cecdda;
    box-shadow: 0 0 10px #cecdda;
}

#component-question .number-question {
    margin-bottom: 8px;
}

#component-question .answer-question {
    padding: 8px;
}

#component-question .background-question {
    background-color: #e7f9f5;
    width: 100%;
    padding: 8px;
}

#component-question .wraper-question {
    /*min-height: 100vh;*/
    /*overflow-y: scroll;*/
}

#component-question .content-heading-question,
#component-question .content-name-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    font-size: 16px;
    color: #0a1d42;
    line-height: 1.63;
    padding-top: 8px;
    display: block;
}

#component-question .question-number {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

#component-question .padding-question-content {
    padding: 8px 8px 0 8px
}

#component-question .width-reset-question-link {
    width: 75px
}


/*----------------------------------------Riview css--------------------------------------------*/

#question-review .content-question {
    padding: 8px;
}

#question-review .number-question {
    background-color: #e9edf1;
    padding: 8px;
}

#question-review .answer-question {
    padding: 8px;
}

#question-review .background-question {

}

#question-review .wraper-question {
    border-radius: 4px;
    border: solid 1px rgba(197, 208, 222, 0.9);
    background-color: #ffffff;
}

#question-review .content-heading-question,
#question-review .content-name-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    font-size: 16px;
    color: #0a1d42;
    line-height: 1.63;
    padding: 8px;
    display: block;
}

#question-review .question-number {
    font-size: 16px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

#question-review .image-upload {
    min-width: 285px;
}


#create-record .title-record-info {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 15px;
    color: #091e42;
}

.filter-modal .scroll-data-customer {
    overflow-y: scroll;
    max-height: 274px;
    padding:  0;
    background-color: #fff;
}
#create-record-fix .question-number {
    font-size: 17px;
    color: #0a1d42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

#create-record-fix .image-upload {
    min-width: 285px;
}


#create-record-fix .title-record-info {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 15px;
    color: #091e42;
}

.filter-modal .scroll-data-customer .dropdown-item-kiroku {
    border-bottom: 1px solid rgba(77, 82, 89, 0.07) !important;
}

.filter-record {
    font-size: 16px;
    color: #2c3135;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: normal;
    /*font-style: normal;*/
}

.action-record-modal {
    padding-left: 40px;
    padding-right: 50px;
    position: fixed;
    bottom: 24px;
    width: 100%;
}

#edit-record .popover.dropdown-kiroku.dropdown-menu {
    width: 50px;
    min-width: 14rem;
}

.customer-name {
    color : #7f8fa4
}

.action-record .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}

#about-survey .background-question-review {
    padding: 0;
}

span#wave {
    position: relative;
}
.dot {
    display:inline-block;
    width:8px;
    height:8px;
    border-radius:50%;
    margin-right:3px;
    background:#303131;
    animation: wave 1.3s linear infinite;
}

.dot:nth-child(2) {
     animation-delay: -1.1s;
 }

.dot:nth-child(3) {
     animation-delay: -0.9s;
 }

@keyframes wave {
    0%, 60%, 100% {
        transform: initial;
    }

    30% {
        transform: translateY(-10px);
    }
}

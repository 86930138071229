.color-action {
    color: #1991eb;
}

#modal-assign .customize-media.media{
    display: none;
}

.create-header {
    background-color: #e7f9f5;
}


.scroll-data-survey {
    overflow-y: scroll;
    height: 221px;
    background-color: #f8f9fa;
}

.list-modal-type {
    padding: 10px 20px;
    box-shadow: inset 0 -1px 0 0 #dfe3e9;
}

.page-login {
  background-color: #eff3f6;
  height: 100vh;
}

.box-login {
  position:relative;
  height: 100%;
  width:100%;
}
.language .logo-kirokuai {

}

.language .flag {
  float: right;
  display: flex;
  padding-top: 8px;
}


.language .flag .flag-icon.ja {
  box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.04);
}

.language .flag .flag-icon.en {
  margin-left: 10px;
  box-shadow: 1px 2px 3px 2px rgba(0,0,0,0.04);
}

.activeLang {
  border: solid 1px #09c199;
  border-radius: 3px;
}

.unActive {
  opacity: 0.5;
}

.language .flag .flag-icon {
  background-size: cover;
  background-position: center center;
  width: 32px;
  height: 24px;
}

@media only screen and (max-width: 425px) {
  .tile {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff;
  }

  .language {
    padding: 1.5rem 20px;
  }

}


@media only screen and (min-width: 426px) {
  .tile {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    border-radius: 2px;
    width: auto;
    max-width: 375px;
    height: 725px;
    overflow: hidden;
  }

  .language {
    padding: 2rem 20px;
    background-color: #eff3f6;
  }

  .card-login {
    height: 567px;
    background-color: #ffffff;
  }

  .heading-support-page {
    padding: 2rem 20px 45px;
    background-color: #eff3f6;
  }

}

.tile-header {
  padding: 0 20px;
}
.tile-header .login-logo {
  padding-top: 14px;
  margin-bottom: 40px;
  letter-spacing: normal;
  font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
  font-weight: 400;
  /*font-style: normal;*/
  color: #091e42;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.tile .tile-body {
  padding: 0 20px;
}

.tile .tile-body .auth-form-header {
  background-color: transparent;
  border: 0;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: none;
}

.tile .tile-body .auth-form-header h1 {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -.5px;
}

/**
 * Forms
 */
.form-input {
  display: block;
  position: relative;
  margin-bottom: 2rem;
}

input:-webkit-autofill {
  background-color: transparent !important;
}

.form-input > .material-icons ~ input, .form-input > .material-icons ~ .label, .form-input > .material-icons ~ .underline {
  margin-left: 2.25rem;
  width: calc(100% - 2.25rem);
}
.form-input input {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0 0 0.5rem;
  margin-top: 20px;
  outline-style: none;
  width: 100%;
}
.form-input input ~ .label {
  color: #091e42;
  font-weight: 500;
  font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
  /*font-style: normal;*/
  font-size: 14px;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 1.5rem;
  left: 0;
  transition: top .2s,font .2s;
  z-index: 1;
}
.form-input input ~ .underline {
  background-color: #7f8fa4;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.form-input input:hover ~ .underline, .form-input input:focus ~ .underline {
  background-color: #1991eb;
}
.form-input input:hover ~ .label, .form-input input:focus ~ .label {
  color: #1991eb;
}
.form-input input:focus ~ .underline {
  height: 1px;
}
.form-input input:focus ~ .label, .form-input input:valid ~ .label {
  top: 0;
  font-size: 0.85rem;
}
.form-input input:-webkit-autofill ~ .label {
  top: 0;
  font-size: 0.85rem;
}

/**
 * Buttons
 */
.btn {
  border-radius: 4px;
  display: inline-block;
  padding: 0.5rem 1rem;
}

.btn, [role="button"], [type="button"] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
}

.btn-login {
  background-color: #1991eb;
  width: 240px;
  height: 44px;
  border-radius: 4px;
  color: white;
}
.btn-login:hover, .btn-login:focus, .btn-login:active {
  background-color: #007bff;
}

.btn-line-login {
  background-color: #ffffff;
  width: 240px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  color: #091e42;
}
.btn-line-login:hover, .btn-line-login:focus, .btn-line-login:active {
  background-color: #ffffff;
  border: solid 1px  #1991eb;
}

/**
 * Utilities
 */
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.float-right {
  float: right;
}

/**
 * Submit
 */
.submit-container {
  position: relative;
  text-align: center;
  margin-top: 2rem;
}
.submit-container #submit {
  position: relative;
  z-index: 2;
  outline: none;
  opacity: 1;
  transition: transform .1s ease-in, opacity .1s ease-in, border-radius .1s ease-in;
  right: -1px;
}

.label {
    display: inline;
    font-weight: 500;
    padding: .2em 0 .3em;
    font-size: 75%;
    line-height: 1;
    color: #fff;
    text-align: left;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.hr-login {
  position: relative;
  display: block;
  margin-top: 1.5rem;
  color: #7f8fa4;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.hr-login:before {
  content: '';
  width: 100%;
  height: 0;
  border-top: 1px solid #dfe3e980;
  vertical-align: middle;
  position: absolute;
  margin-right: 37px;
  top: 50%;
  right: 64%;
}

.hr-login:after {
  content: '';
  width: 100%;
  height: 0;
  border-top: 1px solid #dfe3e980;
  vertical-align: middle;
  margin-left: 32px;
  position: absolute;
  top: 50%;
  right: -74%;
}

.login-line {
  padding: 0 20px;
  text-align: center;
  margin-top: 2rem;
}

.login-tfa {
  padding: 0 20px;
  text-align: left;
  margin-top: 2rem;
}

.line-icon {
  margin-left: 25px;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: cover;
}

.change-status-password {
  float: right;
  margin-top: 25px;
  margin-left: -25px;
  position: relative;
  color: #bcbec7;
}

.page-support {
  background-color: #eff3f6;
  height: 100vh;
}

.support-page-header{
  padding-top: 10rem !important;
  text-align: center;
  height: 120px;
}

.heading-support-page-form {
  font-size: 40px;
  text-align: center;
  font-weight: 500;
  font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
  /*font-style: normal;*/
  color: #2da1f8;
}

.sub-title-support-page-form {
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
  font-weight: 200;
  /*font-style: normal;*/
  color: #7f8fa4;
}

/*@font-face {*/
/*    font-family: NotoSansCJKjp-Bold;*/
/*    src: url(/assets/fonts/NotoSansJP-Bold.otf);*/
/*}*/

/*@font-face {*/
/*    font-family: NotoSansCJKjp-Medium;*/
/*    src: url(/assets/fonts/NotoSansJP-Medium.otf);*/
/*}*/

/*@font-face {*/
/*    font-family: tbudgothic-std,sans-serif;*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/

label {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

.f-bold {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

.slider-navigation ul .menu-item .title-menu,
.bla {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    font-size: 17px;
}

body,
span,
pre {
    font-size: 15px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
}

a {
    text-decoration: none !important;
}

.btn-kiroku {
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(to top, #f2f4f7, #fff);
    min-width: 120px;
    width: 100%;
    height: 36px;
}

.overflow {
    overflow: hidden;
}

.dropdown-kiroku.dropdown-menu {
    padding: 0;
    width: 100%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 100;
    min-width: 120px;
}

.customize-media.media {
    padding: 16px 4px;
}

.dropdown-item-kiroku {
    overflow-x: auto;
    display: block;
    width: 100%;
    padding: 10px 10px;
    clear: both;
    font-weight: 400;
    color: #354052;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;
    border-bottom: solid 1px #d8dadd;
}

.dropdown-kiroku.dropdown-menu.show.top {
    bottom: 100%;
}

.dropdown-item-kiroku:hover {
    background-color: #f1f4f8;
    color: #2ea2f8;
}

.dropdown .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 .3rem;
    left: 124px;
    top: 10px
}

.icon-kiroku {
    color: #a8aab7;
    font-size: 10px;
    position: absolute;
    top: 11px;
    right: 12px;
}

.left {
    float: left;
}

.text-kiroku {
    color: #354052;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
}

.btn.btn-kiroku-primary {
    border-radius: 4px;
    border: solid 1px #1585d8;
    background-image: linear-gradient(to top, #1991eb, #2da1f8);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-primary:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-primary:active {
    color: #fff;
    border: solid 1px #1585d8;
    background-image: linear-gradient(to top, #1991eb, #2da1f8) !important;
    cursor: pointer;
}

.btn.btn-kiroku-success {
    border-radius: 4px;
    border: solid 1px #249533;
    background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-success:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-success:active {
    border-radius: 4px;
    border: solid 1px #249533;
    background-image: linear-gradient(to bottom, #39b54a, #34aa44 98%) !important;
    color: #fff;
}

.btn.btn-kiroku-warning {
    border-radius: 4px;
    border: solid 1px #f36a19;
    background-image: linear-gradient(to bottom, #f7981c, #f76b1c);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-warning:active {
    border-radius: 4px;
    border: solid 1px #f36a19;
    background-image: linear-gradient(to bottom, #f7981c, #f76b1c) !important;
    color: #fff;
}

.btn.btn-kiroku-warning:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-danger {
    border-radius: 4px;
    border: solid 1px #db161e;
    background-image: linear-gradient(to bottom, #f85359, #dc151d);
    padding: 5px 16px;
    color: #fff;
}

.btn.btn-kiroku-danger:active {
    border-radius: 4px;
    border: solid 1px #db161e;
    background-image: linear-gradient(to bottom, #f85359, #dc151d);
    color: #fff;
}

.btn.btn-kiroku-danger:hover {
    color: #fff;
    cursor: pointer;
}

.btn.btn-kiroku-white {
    border-radius: 4px;
    border: solid 1px #ced0da;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    padding: 5px 16px;
    color: #354052;
}

.btn.btn-kiroku-white:active {
    border-radius: 4px;
    border: solid 1px #ced0da;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    color: #354052;
}

.btn.btn-kiroku-white:hover {
    color: #354052;
    cursor: pointer;
}

.btn.btn-kiroku-light {
    border-radius: 4px;
    border: solid 1px #ced0da;
    background-image: linear-gradient(to top, #dfe1e5, #ffffff);
    padding: 5px 16px;
    color: #354052;
}

.btn.btn-kiroku-light:hover {
    color: #354052;
    cursor: pointer;
}

.btn.btn-kiroku-transparent {
    border-radius: 4px;
    border: solid 1px transparent;
    padding: 5px 16px;
    color: #354052;
}

.btn.btn-kiroku-transparent:hover {
    color: transparent;
    cursor: pointer;
}

.btn-outline-search {
    /*border-color: #dbe2e7;*/
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #ffffff;

}

.btn-outline-search:not(:disabled):not(.disabled):active {
    color: #000;
    background-color: #fff;
    /*border-color: #6c757d;*/
}

.check-group {
    display: block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

.check-group input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.check-group input:disabled ~ .checkmark {
    cursor: not-allowed;
}

input:disabled {
    cursor: not-allowed;
}

button:disabled {
    cursor: not-allowed !important;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #dee2e6;
    border-radius: 4px;
}

.check-group input:checked ~ .checkmark {
    border: solid 1px #27aa11;
    background-image: linear-gradient(to top, #29b311, #57d841);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-group input:checked ~ .checkmark:after {
    display: block;
}

.check-group .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 .12em .12em 0;
    transform: rotate(45deg);
}

.form-control-kiroku {
    display: block;
    padding: 6px 15px;
    border-radius: 4px;
    border: solid 1px #dfe3e9;
    background-color: #ffffff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.pr-5px {
    padding-right: 5px;
}

.fa.fa-calendar {
    color: #a8aab7;
}

.media {
    display: flex;
    padding: 16px 12px;
    transition: background-color .2s linear;
}

.media-single {
    align-items: center;
}

.media.media-single .media-body a {
    text-decoration: none !important;
}

.ps-container {
    position: relative;
    touch-action: auto;
    /*overflow: auto;*/
}

.media-list-divided > .media:not(.media-list-header):not(.media-list-footer), .media-list-divided .media-list-body > .media {
    border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.media-list > .media:not(.media-list-header):not(.media-list-footer), .media-list .media-list-body > .media {
    margin-bottom: 0;
}

.media-single, .media-center-v {
    -webkit-box-align: center;
    align-items: center;
}

.media {
    padding: 16px 12px;
    display: flex;
    transition: background-color .2s linear;
}

.media-body {
    min-width: 0;
    flex: 1;
}

.media-body > * {
    margin-bottom: 0;
}

.media > * {
    margin: 0 8px;
}

small {
    color: #8b95a5;
}

.card-title {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
    line-height: 1.5;
    margin-bottom: 0;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.card {
    border: 0;
    border-radius: 0;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
    transition: .5s;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}

.badge-kiroku {
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    padding: 8px 8px 8px 8px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-transform: uppercase;
    font-family: Noto Sans Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 700;
    /*font-style: normal;*/

}

.badge-kiroku.badge-kiroku-default {
    border: solid 1px #ced0da;
    background-color: #fff;
    color: #354052;
}

.badge-kiroku.badge-kiroku-primary {
    border: solid 1px #1991eb;
    background-color: #1991eb;
}

.badge-kiroku.badge-kiroku-success {
    border: solid 1px #36af47;
    background-color: #36af47;
}

.badge-kiroku.badge-kiroku-warning {
    border: solid 1px #f7981c;
    background-color: #f7981c;
}

.badge-kiroku.badge-kiroku-danger {
    border: solid 1px #ed1c24;
    background-color: #ed1c24;
}

.badge-kiroku.badge-kiroku-dark {
    border: solid 1px #516173;
    background-color: #516173;
}

.badge-kiroku.badge-kiroku-light {
    border: solid 1px #dfe1e5;
    background-color: #dfe1e5;
    color: #354052;
}

.badge-kiroku.badge-kiroku-muted {
    border: solid 1px #c2cad4;
    background-color: #c2cad4;
}

.action-customer-surveys .action-icon {
    padding: 0 10px;
}

@media (max-width: 375px) {
    .action-customer-surveys .survey-actions {
        padding: 0 0 0 0 !important;
    }
}

.action-customer-surveys .survey-actions {
    padding: 0 10px 0 0;
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover:after {
    border-bottom: 9px solid rgb(24, 25, 69);
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover {
    background-color: #091e42;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.24);
}

.action-customer-surveys .dropdown-item-kiroku {
    background-color: #091e42;
    border-radius: 4px;
    color: #2ea2f8;
}

.action-customer-surveys .dropdown-item-kiroku:hover {
    background-color: #091e42;
    color: #2ea2f8;
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover .dropdown-item-kiroku {
    color: #ffffff;
}

.dropdown .popover {
    right: -10px;
    top: 7px;
    overflow: visible !important;
    border: 1px solid rgb(229, 230, 231);
    border-radius: 2px;
}

.dropdown div.dropdown-kiroku.popover {
    border-radius: 4px;
    box-shadow: none;
    margin-top: 20px;
    width: 240px;
}

.action-customer-surveys .dropdown div.dropdown-kiroku.popover {
    border-radius: 4px;
    box-shadow: none;
    margin-top: 20px;
    width: 300px;
}

.dropdown div.dropdown-kiroku.popover:before {
    left: auto;
    right: 20px;
    content: '';
    position: absolute;
    top: -11px;
    width: 0;
    height: 10px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgb(229, 230, 231);
}

.dropdown div.dropdown-kiroku.popover:after {
    left: auto;
    right: 20px;
    top: -9px;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgb(255, 255, 255);
    border-left: 7px solid transparent;
}

.popover {
    top: 4px;
    left: -243px;
    max-width: 300px;
}

.divider {

}

.default-background-color {
    background-color: #eef3f6;
    min-height: 100vh;
}

.stylish-input-group .input-group-append .btn-outline-secondary {
    border-color: #ccd2da;
}

.width-40 {
    width: 40%;
}

.mt-16 {
    margin-top: 16px;
}

.mt-12 {
    margin-top: 12px;
}

.label-draft {
    width: 54px;
    height: 20px;
    border-radius: 3px;
    background-color: #f7981c;
    color: #ffffff;
}

.ml-6 {
    margin-left: 6px;
}

.mr-6 {
    margin-right: 6px;
}

.mt-8 {
    margin-top: 8px;
}

.ml-12 {
    margin-left: 12px;
}

.mt-17 {
    margin-top: 17px;
}

.mt-25 {
    margin-top: 25px;
}

.ml-8 {
    margin-left: 8px;
}

.mr-12 {
    margin-right: 12px;
}

.mb-11 {
    margin-bottom: 11px;
}

.mt-7 {
    margin-top: 7px;
}

.pt-8 {
    padding-top: 8px;
}

.mt-11 {
    margin-top: 11px;
}

.modal-kiroku {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    outline: 0;
}

.modal-footer-kiroku {
    justify-content: center;
}

.display-none {
    display: none;
}

.border-modal {
    border-radius: 4px;
}

.kiroku-btn-data {
    padding: 2px 8px;
    border-radius: 16px;
    border: solid 1px rgba(197, 208, 222, 0.9);
    background-color: #fff;
}

.kiroku-btn-data-more {
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 16px;
    border: solid 1px #1991eb;
    background-color: #fff;
    color: #1991eb;
}

.kiroku-btn-data-more:active {
    padding: 2px 8px;
    border-radius: 16px;
    border: solid 1px #007bff;
    background-color: #fff;
    color: #007bff;
}

.spinner {
    position: relative;
    margin: auto;
    background-image: url("https://apptractor.ru/wp-content/uploads/2017/04/spinner-icon-0.gif");
    background-size: cover;
    background-position: center center;
    width: 20px;
    height: 20px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker-wrapper .react-datepicker__input-container {
    display: block !important;
}

.modal-title {
    width: 100%;
}

.btn-icon {
    border-color: #ccd2da;
    border-bottom: 0;
    border-top: 0;
    border-radius: 4px 0 0 4px !important;
    background-color: #ffffff;
}

.action-input {
    position: absolute;
    right: 10px;
    top: 6px;
}

.action-input-icon {
    position: absolute;
    left: 10px;
    top: 6px;
}

/*----------------upload file---------------*/

.wrap-upload {
    position: relative;
    margin: auto;
    display: flex;
}

.form-upload {
    margin: auto;
}

.image-upload {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 300px;
    height: 204px;
    min-width: 300px;
}

.image-upload-default {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 300px;
    height: 190px;
    min-width: 267px;
    border: dashed 1px #09c199;
}

.overlay-upload-file {
    position: relative;
    margin: auto;
    max-width: 300px;
}

.content-upload {
    position: relative;
    margin: auto;
    text-align: center;
}

.content-upload button {
    width: 100%;
    border-radius: 0 !important;
}

.form-upload .overlay-upload-file {
    opacity: .7;
}

.overlay-upload-file-default {
    position: relative;
}

.overlay-upload-file-default i {
    position: absolute;
    /*margin: auto;*/
    top: 65px;
    bottom: 0;
    right: 0;
    left: 0;
}

.overlay-upload-file-default span {
    position: absolute;
    /*margin: auto;*/
    top: 95px;
    bottom: 0;
    right: 0;
    left: 0;
}

.overlay-upload-file-default .fas {
    font-size: 1.6rem;
    padding-right: 5px;
    color: #6c757d;
}

.change-photo {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #09c199;
    padding-left: 3px;
}

.react-phone-number-input .react-phone-number-input__row .react-phone-number-input__icon img {
    vertical-align: top;
}

.react-phone-number-input__row {
    border: solid 1px #ced4da;
    border-radius: 4px;
    background-color: #ffffff;
    background-clip: padding-box;
}

.react-phone-number-input__icon {
    margin-left: 1rem !important;
    border: 0 !important;
}

.react-phone-number-input input {
    border: none;
    padding: 18px 15px;
}

.react-phone-number-input__country--native {
    margin-right: 0 !important;
}

.content-question .form-control:disabled {
    background-color: white;
}

.content-question .form-area:disabled {
    background-color: white;
}

.dd-wrapper {
    padding: 8px;
}

.menu-dd-wrapper {
    margin-top: 2px;
    border: solid 1px #dfe3e9;
    background-color: #f7f7f7;
}

.menu-dd-wrapper .dropdown-item-kiroku {
    border: none;
}

.fix-icon-align i {
    position: relative;
    margin: auto 0;
}

.fix-icon-align a {
    color: #ffffff !important;
}

#multi-select .css-vj8t7z {
    border: solid 1px #dfe3e9;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.content-pagination {
    border: solid 1px transparent;
    padding: 5px 14px;
    color: #7f8fa4;
}

.total-pagination {
    color: #7f8fa4;
    text-align: right;
}

/*------------------------------------table--------------------------------------*/

#table table {
    width: 100%;
    table-layout: auto;
    background-color: #fff;
    padding-bottom: 12px;
}

#table .tbl-header {
    background-color: rgba(255, 255, 255, 0.3);
}

#table .tbl-header tr {
    border-bottom: 1px solid #dfe2e5;
}

#table .tbl-content {
    overflow-x: auto;
    margin-top: 0;
}

#table .tbl-content tr:hover {
    background: rgb(238, 243, 246);
}

#table .tbl-content tr:nth-child(even) {
    background: rgba(197, 208, 222, 0.1)
}

#table .tbl-content tr:nth-child(even):hover {
    background: rgb(238, 243, 246);
}

#table th {
    padding: 20px 15px;
    text-align: left;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
    color: #7f8fa4;
    text-transform: uppercase;
}

#table td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    color: #091e42;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.on {
    /*box-sizing: border-box;*/
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    max-height: 250px;
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
    transition: 0.3s ease-out;
}

.off {
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 9999;
    width: 100%;
    max-height: 250px;
    transition: 0.3s ease-out;
}

.notification {
    padding: 8px 15px 15px 15px;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    min-height: 60px;
    line-height: 1.2em;
    position: relative;
}

.notification .close-icon {
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

.notification .fa-check-circle {
    font-size: 20px;
    float: left;
    position: relative;
    margin: auto;
}

.card-kiroku-web {
    border-radius: 0;
    border: solid 1px #dfe2e5;
}

.card-title-web {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0;
    padding-bottom: 0;
}

#web-q .card-kiroku-web {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-bottom: 0;
}

.kiroku-action-survey {
    width: 24px;
    height: 24px;
    border: 2px solid #a8aab7;
    border-radius: 50%;
    color: #a8aab7;
}

.card-title.card-title-web.bg-success {
    background-color: #e7f9f5 !important;
}

.as {
    position: relative;
    margin: auto;
}

.css-12jo7m5 {
    overflow: visible !important;
}

.form-error {
    border: solid 1px #d30f00 !important;
}

#component-question textarea:disabled {
    background-color: #ffffff;
}

.change-status-password-user {
    position: absolute;
    top: 20%;
    right: 8px;
}

#no-results {
    background-color: #ffffff !important;
}

.align-icon {
    vertical-align: baseline;
    padding-left: 10px;
    color: #c7d2e0
}

.title-label-select-team {
    font-weight: bold;
    color: #36af47;
}

.title-label-select-user {
    font-weight: bold;
    color: #17a2b8 !important;
}

.title-label-select-survey {
    font-weight: bold;
    color: #17a2b8;
}

.badge-select-team {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #36af47;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center
}

.badge-select-user {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #17a2b8;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center
}

.badge-select-survey {
    background-color: #EBECF0;
    border-radius: 2em;
    color: #516173;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    min-width: 1px;
    padding: 0.16666666666667em 0.5em;
    text-align: center
}

.representative-tag:after {
    content: '\A';
    white-space: nowrap;
}

.tags-dropdown {
    padding-top: 5px;
}

.kiroku-data-view-more {
    padding: 2px 8px;
    border-radius: 5px;
    border: solid 1px rgba(197, 208, 222, 0.9);
    height: 27px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
}

.btn.btn-kiroku-beta {
    background-color: #ffff;
    background-image: linear-gradient(to top, #ffffff, #ffffff);
    border: solid 1px #007BFF;
    color: #007BFF;
}

.answer-checkbox-beta .check-group .checkmark::after {
    left: 4px;
    top: 1px;
    width: 9px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 .12em .12em 0;
    transform: rotate(45deg);
}

.answer-checkbox-beta .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dee2e6;
    border-radius: 4px;
}

.avatar-user-comment {
    height: 40px;
    width: 40px;
    float: left;
    position: relative;
    margin-right: 10px;
    text-align: center;
    border-radius: 100%;
    background-position: center center;
    background-size: cover;
}
.content-comment .author {
    font-size: 16px;
    font-weight: 500;
    color: #007bff;
}
.content-comment .meta-data .date {
    color: #7f8fa4;
}
.lds-ellipsis-loading {
    display: inline-block;
    position: relative;
    width: 11px;
    height: 11px;
}

.lds-ellipsis-loading div {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #565656;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis-loading div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis-loading div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis-loading div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis-loading div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis-loading1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis-loading3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis-loading2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}

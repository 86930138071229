.report-tab {

}

.report-tab .wrap-content {
    /*padding-top: 80px;*/
    padding-bottom: 80px;
}

.report-tab .wrap-content .A4 {
    width: 21cm;
    /*border: solid 1px #DDDD;*/
    /*box-shadow: 0 0 0.1cm rgba(0, 0, 0, 0.5);*/
    background: #FFFFFF;
    display: block;
    padding: 10px 25px;
    margin: 20px auto 0.5cm;
    box-sizing: border-box;
    font-size: 14px;
}

.report-tab .wrap-content .A4 .content {

}

.report-tab .wrap-content .A4 .content .wrap-bg {
    background-color: #FFF;
}

.striped-list .striped-item:nth-child(even) {
    background-color: #fcfcfc
}

label.report-tab {
    color : #7f8fa4;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 14px;
}

img.report-tab {
    width: 100%;
    height: auto;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
}

.border-title-template {
    border-bottom: 5px solid #212529;
    font-size: 16px;
}

.border-customer-name {
    border-bottom: 2px solid #212529;
    font-size: 16px;
}

.report-list {
    border-bottom: 1px solid #707070 ;
    padding-top: 10px;
}

.answers-list .answer-item:nth-child(even) {
    background-color: rgba(197, 208, 222, .1);
}

.answer-content {
    border: none;
    font-size: 16px;
    width: 100%;
}

textarea.answer-content {
    height: 30px; resize: none;
}

.end-answer {
    border-bottom: 1px solid #707070;
    font-size: 16px;
}

.answer {
    /*background-image: linear-gradient(to right, #cccccc 33%, rgba(255,255,255,0) 0%);*/
    /*background-position: bottom;*/
    /*background-size: 20px 1px;*/
    /*background-repeat: repeat-x;*/
    border-bottom: 1px dashed #DDDDDD;
    font-size: 16px;
}

.question {
    border-bottom: 1px solid #707070 ;
}

.border-bottom-dashed {
    border-bottom: 1px dashed #DDDDDD;
}

.border-bottom-inline {
    border-bottom: solid 1px #7f8fa4;
}

.pb-10px {
    padding-bottom: 10px;
}

.pt-10px {
    padding-top: 10px;
}

.fix-margin-row {
    margin: 0;
}

.fix-padding-col {
    padding-left: 0;
}


@media only screen and (max-width: 768px) {

    div.DateRangePicker.DateRangePicker_1 {
        width: 100% !important;
    }

    .wrap-print {
        width: 100%;
    }

    .opacity-mobile {
        z-index: 998;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 200vh;
        cursor: pointer;
    }

    .opacity-main .overlay {
        /*display: none;*/
    }

    .sidenav.close-slider {
        left: -260px !important;
    }

    .back-up-bars {
        padding-right: 18px;
    }

    #list {
        display: none !important;
    }

    .modal-preview-file .modal-dialog {
        max-width: 90% !important;
        margin: 1.75rem auto;
    }

    #medium {
        display: none !important;
    }

    #web {
        display: none !important;
    }

    .padding-responsive {
        padding: 0.5rem;
    }

    .card-dashboard {
        height: 96px;
        width: 50%;
    }

    .notification-container {
        position: fixed;
        width: 100%;
    }

    main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        max-width: 375px;
        height: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    #fix-date-rage .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
        /*width: 90%;*/
        /*overflow-x: auto;*/
    }

    .rotate-buttons{
        bottom: 50px !important;
    }

    .rotatable-image {
        width: 90vw !important;
        height: 90vw !important;
    }
}

@media only screen and (min-width: 1441px) {
    .header-mobile {
        margin-left: 260px;
        transition: 0.3s ease-out;
    }

    .header-mobile.opacity-header {
        margin-left: 53px;
        transition: 0.3s ease-out;
    }

    .main-container {
        margin-left: 260px;
        transition: 0.3s ease-out;
    }

    .main-container.main-collapse {
        margin-left: 53px;
        transition: 0.3s ease-out;
    }

    .padding-responsive {
        padding: 1rem;
    }

    #medium {
        display: none !important;
    }

    #mobile {
        display: none !important;
    }

    .open-slide {
        left: 260px
    }

    .sidenav {
        border-right: 1px solid #dfe2e5;
        z-index: 997 !important;
    }

    .header-mobile-left i {
        display: none !important;
    }

    #overlay-main.overlay {
        display: none;
    }

    #main.opacity-main {
        height: 0;
        position: relative;
    }

    .card-dashboard {
        width: 33%;
    }

    .notification-container {
        top: 20px;
        position: fixed;
        width: 60%;
    }

    .notify-web-a {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        max-width: 500px;
        height: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
    .back-up-bars{
        display: none;
    }
    .main-container {
        margin-left: 260px;
        transition: 0.3s ease-out;
    }

    .main-container.main-collapse {
        margin-left: 53px;
        transition: 0.3s ease-out;
    }

    .header-mobile {
        margin-left: 260px;
        transition: 0.3s ease-out;
    }

    .header-mobile.opacity-header {
        margin-left: 53px;
        transition: 0.3s ease-out;
    }

    .overlay {
        /*display: none;*/
    }

    .padding-responsive {
        padding: 1rem;
    }

    #mobile {
        display: none !important;
    }

    #list {
        display: none !important;
    }

    .open-slide {
        left: 260px
    }

    .card-dashboard {
        width: 33%;
    }

    .notification-container {
        top: 20px;
        position: fixed;
        width: 60%;
    }

    .notify-web-a {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form-search-survey-web {
        min-width: 100px;
        width: 150px;
        padding-left: 8px;
    }

    main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        max-width: 500px;
        height: 400px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}


.DayPicker_weekHeader {
    top: 102px !important
}

.DayPicker_focusRegion {
    padding-top: 50px;
}

.modal-preview-file .modal-dialog {
    max-width: 1235px;
    margin: 1.75rem auto;
}

.modal-preview-file .modal-content img {
    width: auto;
    height: 539px;
    max-height: 539px;
}

.modal-preview-file .modal-dialog.modal-dialog-centered
{
    height: 100%;
}
.modal-preview-file .modal-content.pdf {
    height: 90%;
}
.modal-preview-file .modal-content.png {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: 0;
    max-height: 539px;
    max-width: 1235px;
}

.modal-preview-file .modal-content iframe {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {

    .DayPicker_weekHeader {
        top: 120px !important
    }

    .DayPicker_focusRegion {
        padding-top: 60px;
    }

    .modal-preview-file .modal-content img {
        width: 100%;
        height: auto;
        max-height: 594px;
    }

    .modal-preview-file .modal-dialog {
        width: 100%;
        margin: 1.75rem auto;
    }

    .close-modal-preview.png {
        top: -50px !important;
        right: 0 !important;
    }
}

.close-modal-preview.pdf {
    position: absolute;
    top: -50px;
    right: 0;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close-modal-preview.png {
    position: absolute;
    top: -50px;
    right: 0;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

@media (min-width: 768px) {

    .menu-link:hover {
        background-color: #cde7fd !important;
        border-left: 2px solid #1991eb !important;
    }

    .menu-link:hover > * {
        color: #1991eb;
    }

    .wrap-print {
        width: 50%;
    }

    .modal-survey .modal-dialog {
        width: 70% !important;
        max-width: 70%;
    }
}

.close-modal-preview:hover,
.close-modal-preview:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.modal-preview-file {
    position: relative;
}

@media (min-width: 375px) {
    .tags-list .dropdown-kiroku.dropdown-menu.headerm {
        min-width: 138px;
    }

}

@media (max-width: 850px) {
    .text-page-size {
        display: none;
    }
}

.scroll-data-logic-single {
    overflow-y: auto;
    max-height: 60vh;
}

.group-btn{
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 99;
}

.rotatable-image {
    width: 90vh;
    height: 90vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

@media only screen and ( max-width: 850px) {
    .hidden-username-on-header {
        display: none;
    }
}

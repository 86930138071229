.sumary-content {
    width: 50%;
}

.sumary-content2 {
    width: 60%;
}

.content-check {
    width: 80%;
}

.action-fix {
    position: relative;
    margin: auto;
}

.action-fix-plus .kiroku-action-survey {
   border-color: #1991eb;
}

.action-fix-plus i,
.action-fix-plus span {
    color: #1991eb;
}

.fix-center {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.wrap-overlay-action {
    position: relative;
    width: 100%;
    height: 100%;
}

.overlay-action {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    right: -110px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.card-body .wrap-overlay-action {
    opacity: 0.3;
}

.card-body:active .wrap-overlay-action {
    opacity: 1;
}

.position-survey {
    background-color: transparent;
    padding: 10px 5px;
    border: solid 1px transparent;
    cursor: pointer;
}

button:disabled.position-survey {
    color: #6c757d7d;
}
.card-body:hover .overlay-action {
    opacity: 1;
}

.font-text-header-card {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}
.group-select-type-question-on-survey {
    width: 100%;
}


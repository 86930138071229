.title-table-del {
    font-size: 18px;
}

.title-table-del-name {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 300;
    /*font-style: normal;*/
}

.header-table-del {
    font-size: 14px;
    color: #757575 !important;
}

.font-size-16 {
    font-size: 16px;
}

.hr-table-mobile {
    color : #F2F3F3;
    size: 1px;
    margin-bottom: 0;
}
.title-card-del {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color : #202528;
}

.header-card-del {
    color: #007bff;
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    font-weight: 300;
}

.card-body-del {
    width: 100%;
}

.card-media-screen {
    width: 100%
}
.create-del-at {
    color: black;
}

.font-size-condition {
    width: 50%;
}

.width-full {
    width: 100%;
}

.font-size-condition input {
    font-size: 16px;
}

.font-size-condition ::placeholder {
    color: #7f8fa4;
}

.icon-kiroku-white {
    color: #a8aab7;
    font-size: 10px;
    position: absolute;
    top: 13px;
    right: 12px;
}
.btn-kiroku-white-del {
    border-radius: 4px;
    border: solid 1px #E3E6EA !important;
    min-width: 120px;
    width: 100%;
    height: 38px;
    background-image: none !important;
    background-color: #FFFFFF !important;
}
.color-text-drop {
    color: #7f8fa4;
}
.color-blue-icon-order{
    color: #4290E1;
}

tr.border-bottom > td{
    border-bottom: 1px solid #E5E9EC !important;
}

tr.border-bottom-none > td {
    border-bottom: none !important;
}

.deleted-list-th{
    padding: 10px 15px !important;
}

#deleteWebPage #table .tbl-content tr:nth-child(even) {
    background: rgb(255, 255, 255) !important;
}

#deleteWebPage #table .tbl-content tr:nth-child(even):hover {
    background: rgba(197, 208, 222, 0.3) !important;
}

#deleteWebPage #table .tbl-header tr {
    border-bottom: 1px solid #E5E9EC !important;
}

.field-date-time-del {
    color: #202528 !important;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.pd-top-10{
    padding-top: 10px;
}

.date-time-item{
    display: inline-block;
    text-align: right;
}

.year-of-action{
        width: 75px;
}

.month-of-action{
    width: 47px;
}

.date-of-action{
    width: 47px;
}

.hour-of-action{
    width: 35px;
}

.minute-of-action{
    width: 20px;
}

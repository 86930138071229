.date-log {
    color: #6c798f;
}

.avartar-log {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-size: cover;
    background-position: center center
}
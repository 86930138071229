.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
}

.default-background-color {
    background-color: #eef3f6;
    min-height: 100vh;
}

.main-content-mobile {
    padding-top: 70px;
}

.header-mobile {
    position: fixed;
    display: flex;
    height: 70px;
    max-height: 70px;
    padding: 0 18px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 995;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.08);
}

.title-page-component {
    font-size: 18px;
    color: #091e42;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
}

.header-mobile-left {
    display: flex;
    align-items: center;
}

.sidenav.close-slider .divclass:hover .fa.fa-bars:before {
    content: "\F061";
    cursor: pointer;
}

.sidenav.close-slider .title-menu {
    display: none !important;
}

.sidenav.close-slider .menu-link {
    display: flex;
    flex-direction: row-reverse;
}

.sidenav.close-slider .menu-item.active .menu-link {
    border-right: 2px solid #1991eb !important;
}

.sidenav.open-slide .divclass:hover .fa.fa-bars:before {
    content: "\F060";
    cursor: pointer;
}

.divclass {
    font-size: 20px;
}

.fa.fa-bars {
    color: #1991eb;
    font-size: 20px;
    cursor: pointer;
}

.header-mobile-right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.header-user-avatar {
    margin-top: 0;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}

.header-user-avatar .avatar {
    cursor: pointer;
    z-index: 100;
    position: relative;
    margin-left: 12px;
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 100%;
    background-position: center center;
    background-size: cover;
}

.header-notify {
    display: inline-block;
}

.ant-badge {
    position: relative;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
}

.sup-count {
    position: absolute;
    transform: translateX(-50%);
    top: -3px;
    height: 15px;
    border-radius: 10px;
    min-width: 15px;
    background: #f04134;
    color: #fff;
    line-height: 15px;
    text-align: center;
    padding: 0 2px;
    font-size: 12px;
    white-space: nowrap;
    transform-origin: -10% center;
}

.notify {
    font-size: 35px;
    color: #7f8fa4;
    padding-top: 3px;
    padding-right: 10px;
}

.topbar-divider {
    border-left: 1px solid rgba(77, 82, 89, 0.07);
    height: 20px;
    align-self: center;
    margin: 0 5px;
}

.dropdown-kiroku.dropdown-menu.headerm {
    right: 5px;
    top: 62px;
    width: auto;
    overflow: visible !important;
    border: 1px solid rgb(229, 230, 231);
    border-radius: 2px;
}

.dropdown-kiroku.dropdown-menu.headerm:before {
    left: auto;
    right: 21px;
    content: '';
    position: absolute;
    top: -11px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgba(0, 0, 0, 0.07);
}

.tags-list .dropdown-kiroku.dropdown-menu.headerm:before {
    left: 10px;
}

.tags-list .dropdown-kiroku.dropdown-menu.headerm:after {
    left: 10px;
}

.representative-tag {
    display: inline-block;
}

/*.tags-list span{*/
/*    font-size: 12px;*/
/*}*/

/*.certificate-list span {*/
/*    font-size: 12px;*/
/*}*/

.dropdown-kiroku.dropdown-menu.headerm:after {
    left: auto;
    right: 21px;
    top: -9px;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgb(255, 255, 255);
    border-left: 7px solid transparent;
}

.dropdown-kiroku.dropdown-menu.headerm .dropdown-item {
    color: #4d5259;
    font-size: 14px;
}

.dropdown-kiroku.dropdown-menu.headerm .dropdown-item:hover {
    background: #f5f5fa;
}

.wraper-avatar {
    display: flex;
    color: #7f8fa4;
}

.item-user-profile {
    padding: 10px 26px;
}

.fs-16px {
    font-size: 16px;
}

.dropdown-item > * {
    color: #7f8fa4;
}

.avatar-line-login {
    position: relative;
    margin-top: 2px;
    width: 24px;
    height: 24px;
    background: url("/assets/img/line-me.png") center center;;
    background-size: cover;
}

.sidenav {
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -207px;
    background-color: #fff;
    border-right: 1px solid #dfe2e5;
    overflow-x: hidden;
    transition: 0.3s ease-out;
}

.open-slide {
    left: 0;
}

.display-none {
    display: none;
}

.display-none-important {
    display: none !important;
}

.opacity-main {
    z-index: 998;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh;
    cursor: pointer;
}

.overlay {
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: black;

}

.opacity-header {
    z-index: 995 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #fff;
}

.opacity-image {
    opacity: 0.3;
}

.slider-close {
    position: fixed;
    top: 8px;
    color: #ffffff;
    right: 23px;
    font-size: 24px;
}

.header-slidenav {
    height: 70px;
    background-color: #fff;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfe2e5;
}

.slider-navigation {
    position: relative;
    overflow: hidden;
    flex: 1 1;
    touch-action: auto;
}

.menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
}

.menu-category {
    position: relative;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    padding: 1rem 20px 0.5rem;
    color: #7f8fa4;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-category:after {
    content: '';
    width: 100%;
    height: 0;
    border-top: 1px dashed #7f8fa4;
    vertical-align: middle;
    margin-left: 1.5rem;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    margin-top: 3px;
}

.menu-item {
    vertical-align: top;
    transition: opacity 0.2s linear;
    cursor: pointer;
}

a.menu-item {
    text-decoration: none !important;
}

.menu-item .menu-link {
    color: #7f8fa4;
    height: 56px;
    padding: 0 12px;
    font-weight: 400;
    display: -webkit-box;
    display: flex;
    align-items: center;
    transition: .2s linear;
}

.menu-item.active .menu-link {
    background-color: #cde7fd !important;
    border-left: 2px solid #1991eb !important;
}

.menu-item.active .menu-link > * {
    color: #1991eb !important;
}

.menu-item .menu-link .title-menu {
    flex-grow: 1;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    flex-direction: column;
    line-height: 1;
}

.menu-item .menu-link > * {
    margin-left: 8px;
    margin-right: 8px;
}

.logo-kirokuai {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1991eb;
}

.content-checkbox {
    margin-top: -4px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-family: Noto Sans JP, sans-serif;
    /*font-style: normal;*/
    font-weight: normal;
}

.form-area {
    padding: 6px 15px;
    border-radius: 4px;
    border: solid 1px #d8d9dc;
    min-height: 90px;
    width: 100%;
    resize: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #27aa11;
}

.custom-radio .custom-control-input:disabled ~ .custom-control-label::before,
.custom-radio .custom-control-input:disabled ~ .custom-control-label::after
{
    cursor: not-allowed;
}

.setFontSpan {
    font-family: Noto Sans JP,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
}

.view-1vh {
    height: 100vh;
    background-color: #ffffff
}

.filter-by-web {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
}

#web-q .bg-card-web {
    background-color: #e8ecf1 !important;
}

.overlay-action-creator {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000000;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.action-creator {
    z-index: 5;
    position: absolute;
    top: 30%;
    right: 11%;
    bottom: 0;
}

.scroll-data-dropdown-search {
    max-height: 178px;
    overflow-y: auto;
}

.pt-edit {
    padding-top: 0.12rem !important;
}

#fix-date-rage {
    border-radius: 4px;
    height: 36px;
}

#fix-date-rage .DateRangePickerInput_arrow {
    position: relative;
    margin: auto 0;
}

#fix-date-rage .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    /*height: 36px;*/
    /*width: 286px;*/
    display: flex;
    max-width: 100% !important;
}

.exports #fix-date-rage .DateRangePickerInput__withBorder {
    width: 286px;
}

.date-time-range #fix-date-rage .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    height: 36px !important;
    /*width: 286px;*/
    display: flex;
    max-width: 100% !important;
}

.date-time-range #fix-date-rage .DateInput_input.DateInput_input_1 {
    padding: 0.3rem 0 0.3rem 1rem;
    border-bottom: 1px solid #dbdbdb !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    font-size: 14px;
}

#fix-date-rage .DateInput.DateInput_1 {
    height: 35px;
}

#fix-date-rage .DateInput_input.DateInput_input_1 {
    padding: 0.3rem 0 0.3rem 1rem;
    /*border-bottom: 1px solid #dbdbdb;*/
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    font-size: 14px;
}


#fix-date-rage .DateRangePicker.DateRangePicker_1 #endDate {
    border-bottom-left-radius: 0;
}

#fix-date-rage .DateRangePicker.DateRangePicker_1 #startDate {
    border-bottom-right-radius: 0;
}

#fix-date-rage input:focus {
    border-bottom: 2px solid #1991eb !important;
}

#fix-date-rage .CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #1991eb;
    border: 1px double #1991eb;
    color: #fff;
}

#fix-date-rage .DateRangePickerInput_clearDates,
#fix-date-rage .DateRangePickerInput_clearDates:hover {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 6px 8px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.fix-icon-input-search {
    border: 0;
    background: transparent;
}

.fa.fa-search {
    font-size: 16px;
}

#input-form-searcher button:focus,
#input-form-searcher button:hover,
#input-form-searcher button:active {
    outline: none;
    border: none;
}

.react-phone-number-input__country-select {
    pointer-events: none;
}

.react-phone-number-input__country.react-phone-number-input__country--native {
    display: none;
}


.scrollbar {
    height: 200px;
    overflow-y: auto;
}

.force-overflow {
    min-height: 200px;
}


#style-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-scroll::-webkit-scrollbar-thumb {
    background-color: #6c798f;
}

.main-loading .fade.show {
    background: #2c3135d4;
}

.close-modal-preview.pdf {
    position: absolute;
    top: -50px;
    right: 10px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close-modal-preview.png {
    position: absolute;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close-modal-preview:hover,
.close-modal-preview:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.modal-preview-file {
    position: relative;
}


.modal-preview-file .modal-dialog {
    margin: 1.75rem auto;
}

.modal-preview-file .modal-kiroku.fade.show {
    overflow: scroll;
}

.modal-preview-file .modal-dialog.modal-dialog-centered {
    height: 100%;
}

.modal-preview-file .modal-content.pdf {
    height: 90%;
}

.modal-preview-file .modal-content.png {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: 0;
}

.modal-preview-file .modal-content iframe {
    width: 100%;
    height: 100%;
}

.modal-survey .card {
    border-radius: 4px;
    border: 0;
}

.modal-survey .sumary-content2 {
    width: 100%;
}

@media only screen and (min-width: 1400px) {
    .modal-survey .content-check {
        width: 85%;
    }
}

@media only screen and (max-width: 1399px) {
    .modal-survey .content-check {
        width: 100%;
    }
}

.padding-top-2px {
    padding-top: 2px;
}

.scroll_Answer_Question {
    overflow: auto;
    height: 360px;
}

.btn-action-review .btn {
    padding: 3px 13px;
}

.wrap-next-question button {
    background-image: linear-gradient(to top, #dfe1e5, #ffffff);
    padding: 5px 16px;
    color: #354052;
    border: solid 1px #ced0da;
}

.tranfer-text,
.tranfer-text:hover,
.tranfer-text:active {
    color: transparent;
}

.submit-search {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    width: 38px;
    background: #17a2b8;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

.submit-search i {
    padding-left: 12px;
    color: #fff;
    cursor: pointer;
}

.text--a {
    font-size: 85%;
    font-weight: bold;
}

.scroll-history-answer {
    max-height: 582px;
    overflow-y: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-disable {
    cursor: not-allowed !important;
}

input:disabled {
    cursor: not-allowed !important;
}

textarea:disabled {
    cursor: not-allowed !important;
}

.custom-control-disable {
    cursor: not-allowed !important;
}

.group-pin:hover .fas.fa-star {
    color: #f7981c !important;
}

.group-pin:hover .far.fa-star {
    color: #f7981c !important;
}

.bg-disable-question {
    background-color: #6f747b !important;
}

.d-flex-inline {
    display: inline;
}

.read-only-date > input, .serviced-at > input {
    background-color: white !important;
}

.text-page-size {
    color: #7f8fa4;
}

.dropdown-page-size {
    min-width: 60px;
}

.survey-list .page-size-custom .dropdown-kiroku.dropdown-menu.show {
    min-width: 70px;
    height: 181px !important;
}

.print-modal {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.border-service {
    border: 2px solid #dfe3e8;
    border-radius: 4px;
}

.shortcut-wrapper {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.shortcut-content {
    color: #007bff;
    cursor: pointer;
}

.shortcut-content:hover:active {
    color: #0362c7;
}

.btn.btn-switch-1 {
    width: 50%;
    border-radius: 4px 0 0 4px !important;
}

.btn.btn-switch-2 {
    width: 50%;
    border-radius: 0 4px 4px 0 !important;
}

.label-status-record-list {
    border-radius: 12px;
    width: 80px;
    text-align: center;
}

.label-date-time-picker {
    border-radius: 12px;
    width: 100px;
    text-align: center;
    cursor: pointer;
}

.label-date-time-picker.active {
    background-color: rgb(25, 145, 235);
    color: rgb(255, 255, 255);
}

.background-color {
    background-color: #ffffff !important;
}

.icon-color-black i {
    padding-left: 12px;
    color: #e0e3e9;
    cursor: pointer;
}
.highLightText {
    background-color: #ffd640

}
.icon-order .fa-lg.fa-sort-up {
    vertical-align: bottom;
}
.icon-order .fa-lg.fa-sort-down {
    vertical-align: top;
}
.color-blue {
   color: #007bff !important;
}

.wrap-text-customs-date {
    flex-wrap: wrap;
}

.font-size-20 {
    font-size: 20px;
}
.border-status-searching {
    border: 1px solid rgb(25, 145, 235);
}

.react-tooltip-lite {
    background: #333;
    color: white;
}

.react-tooltip-lite-arrow {
    border-color: #333;
}

.text-overflow-elip {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.height-text-next-question {
    height: 24px;
}
.css-1q79lcu {
    font-weight: normal !important;
}
.rdtSwitch, .dow {
    font-weight: 500;
}

.text-black {
    color: #0a1d42;
}

.date-time-range-report #fix-date-rage .DateRangePickerInput_clearDates_default_2 {
    line-height: 9px;
}

.date-time-range-report #fix-date-rage .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    height: 38px !important;
    /*width: 286px;*/
    display: flex;
    max-width: 100% !important;
}

.kiroku-support {
    text-align: center;
    height: 50px;
    color: #007BFF;
}

.input-code {
    border: solid 0;
    border-bottom: solid 1px #dfe3e9;
    width: 75px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #C8CCD4;
    outline: none;
    padding: 1px 1px 1px 3px;
    /*transition: border-bottom 2s;*/
    text-align: center
}

.input-code:focus {
    border: solid 0;
    border-bottom: solid 2px #007bff;
    width: 75px;
    box-shadow: none;
}

.btn-connect-disabled {
    border-radius: 4px;
    width: 200px;
    background-color: #ffffff;
    border: solid 1px #DFE3E8;
    color: #DFE3E8;
    height: 36px;
    line-height: 16px;
}

.btn-connect-active {
    border-radius: 4px;
    width: 200px;
    background-color: #4291E4;
    border: solid 1px #4291E4;
    color: #FFF;
    height: 36px;
    line-height: 16px;
}

.btn-connect-active:active {
    border-radius: 4px;
    background-color: #4291E4;
    color: #FFF;
    line-height: 16px;
}

.padding-location-support-page {
    padding: 90px 1px 1px 1px;
}

.margin-line-back:before {
    margin-right: -27px;
}

.margin-line-back:after {
    margin-right: 70px;
}

.text-question-confirm-addr-header {
    font-size: 16px
}

.text-question-confirm-addr {
    color: #7f8fa4;
    font-size: 14px;
    padding-top: 10px;
}

.btn-cancel-confirm-overwrite-addr {
    border: solid 1px #1891eb;
}

.border-btn-paginator {
    height: 38px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: solid 1px #007BFF;
    color: #007BFF
}

button.border-btn-paginator:disabled{
    height: 38px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: 1px solid #DFE3E8;
    color: #DFE3E8;
    background-color: #FFFFFF !important;
}


.btn-back {
    margin-right: 10px;
    padding: 0 9px 2px 8px;
}

.btn-next {
    margin-left: 10px;
    padding: 0 9px 2px 8px;
}

.btn-action-active {
    width: 100%;
    font-size: 16px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    background-color: #2B91FF;
    color: #ffffff;
}

.btn-action-deActive {
    width: 100%;
    font-size: 16px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #2B91FF !important;
    background-color: #ffffff;
}
.btn-del {
    border-radius: 5px 0 0 5px !important;
    border-color: #2B91FF;
}
.btn-cre {
    border-radius: 0 5px 5px 0 !important;
    border-color: #2B91FF;
}

.font-text-deleted-list {
    color: #7F8FA4 !important;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
}

.font-text-header-deleted-list {
    color: #4290E1 !important;
    font-size: 16px;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
}
.width-table-create-del-at {
    width: 300px;
}

.circle-up-btn .fa-chevron-circle-up{
    color: #007BFF;
}

.font-username {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 20px;
    color: #212529
}

.color-btn-paginator .border-btn-paginator{
    color : #2B91FF;
}

.scroll-header-on-mobile {
    z-index: 995 !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #fff;
}

.header-scroll-mobile {
    display: flex;
    height: 70px;
    max-height: 70px;
    padding: 0 18px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 995;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.08);
}
.text-decoration {
    text-decoration: line-through;
}

.word-break {
    word-break: break-all
}
.text-404 {
    font-size: 179px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: bold;
    color: #e0e0e0;
    line-height: 170px;
}

.text-page-not-found {
    font-size: 35px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 700;
    font-style: normal;
}

.pt-5px {
    padding-top: 5px;
}

.pt-4px {
    padding-top: 4px;
}

.unrequited-checkbox > div > .content-checkbox{
    margin-top: -2px;
}

.keeping-about-survey {
    position : sticky;
    z-index: 997;
}
.text-link {
    color: #007BFF;
    cursor: pointer;
    width: max-content;
    height: max-content;
}

.rotate-buttons{
    position: fixed;
    bottom: 50px;
    display:none;
}

.padding-reset-button-link {
    padding: 0 8px
}
.label-answer-analysis {
    margin-left: 47px;
}

.disabled-link {
    cursor: not-allowed;
    opacity: 0.4;
}

.disabled-link > a {
    pointer-events: none;
}

.customer-list {
    padding-top: 8px;
    background-color: #eef3f6;
}

.customer-list .d-flex .dropdown .btn {
    padding: .473rem .75rem;
}

.customer-list .btn.btn-kiroku-primary {
    padding: 5px 22px;
}

.customer-list .form-group input {
    width: 100%;
}

.customer-list .pr-10 {
    padding: 7px 5px;
}

.customer-list .form-group {
    margin-top: 16px !important;
    margin-right: 0.5rem;
    margin-left: 0.5rem;

}

.customer-list .btn-kiroku {
    min-width: 115px;
}

.customer-list .dropdown-kiroku.dropdown-menu {
    min-width: 115px;
}

.bg-customer-editor {
    background-color: #eef3f6;
}

.customer-list .bulk-action {
    width: 132px;
    height: 36px;
}

.customer-profile .personal-info {
    color: #6c798f;
    font-size: 14px;
}

.action-customer .action-icon {
    padding: 0 10px;
}

.action-customer .dropdown div.dropdown-kiroku.popover:after {
    border-bottom: 9px solid rgb(24, 25, 69);
}

.action-customer .dropdown div.dropdown-kiroku.popover {
    background-color: #091e42;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.24);
}

.action-customer .dropdown div.dropdown-kiroku.popover .dropdown-item-kiroku {
    color: #ffffff;
}

/*----------------------------------------Customer-detail css--------------------------------------------*/

.pt-10 {
    padding-top: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.pt-8 {
    padding-top: 8px;
}

.pl-12 {
    padding-left: 12px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-16 {
    margin-top: 16px;
}

.pt-16 {
    padding-top: 16px;
}

.ml-20 {
    margin-left: 20px;
}

.customer-detail .card-customer-detail {
    border-radius: 1px;
    box-shadow: 1px 0 0 0 #dfe2e5;
    border: solid 1px #dfe2e5;
    border-left: 0;
    border-right: 0;
    padding-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
}

.customer-detail .customer-info {
    color: #091e42;
    font-size: 14px;
}

.customer-detail .margin-tab {
    margin: 0 12px;
}

.customer-detail .personal-info {
    color: #6c798f;
    font-size: 14px;
}

.customer-detail .avatar-staff {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 100%;
    background-position: center center;
    background-size: cover;
    vertical-align: sub;
}

.customer-detail .content-avatar {
    margin-top: 16px;
    color: #7f8fa4
}

.customer-detail .height-avatar {
    height: 98px;
    width: 98px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
}

.customer-detail .btn-change-avatar {
    width: 146px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #1585d8;
    background-color: #39b54a;
}

@media only screen and (max-width: 325px) {
    .customer-detail .nav-bar {
        font-size: 12px;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        font-weight: 500;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }

}

@media only screen and (min-width: 326px) {
    .customer-detail .nav-bar {
        font-size: 13px;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        font-weight: 500;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }
}

.customer-detail .nav-bar.active {
    border-bottom: 2px solid #1991eb !important;
    color: #1991eb;
}

.customer-detail .dropdown-kiroku.dropdown-menu.headerm {
    top: 32px;
}

.main-content-mobile .modal-body {
    padding: 0;
}

.main-content-mobile #body-modal-as .card .media-list.media-list-hover.media-list-divided.scrollable.ps-container.ps-theme-default > div {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    height: 40px;
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    padding: 8px 1rem;
}

.customer-detail .scroll-data-customer,
#body-modal-as .scroll-data-customer,
#assigh-survey .scroll-data-customer {
    overflow-y: scroll;
    height: 221px;
    background-color: #f8f9fa;
}

/*----------------------------------------Customer-editor css--------------------------------------------*/

.pt-10 {
    padding-top: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.ml-20 {
    margin-left: 20px;
}

.customer-detail .card-customer-editor {
    border-radius: 1px;
    box-shadow: 1px 0 0 0 #dfe2e5;
    border: solid 1px #dfe2e5;
    padding-top: 24px;
    padding-bottom: 24px;
}

.customer-detail .content-avatar {
    margin-top: 16px;
    color: #7f8fa4
}

.customer-detail .height-avatar {
    height: 98px;
}

.customer-detail .btn-change-avatar {
    width: 146px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #1585d8;
    background-color: #39b54a;
}

#card-customer .action-customer-surveys .dropdown div.dropdown-kiroku.popover {
    width: 220px;
}

@media only screen and (max-width: 449px) {
    .group-action-customer-create-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .m-20-mb {
        margin-top: 1rem;
    }

    .w-100-mb {
        width: 100%;
    }

    .group-action-customer-create-mobile.mt-24.pb-5 button {
        width: 100%;
        padding: 7px 16px;
    }

    .center-xs {
        text-align: center;
    }
}

@media only screen and (min-width: 450px) {
    .group-action-customer-create-mobile {
        justify-content: center !important;
        -webkit-box-pack: center !important;
        display: flex !important;
    }

    .m-20-mb {
        margin-left: 20px;
    }
}

.wrap-upload-customer {
    border-radius: 4px;
    border: dashed 2px #b9bbbd;
    transition: .5s;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}


.wrap-upload-customer.active {
    border-radius: 4px;
    border: dashed 2px #1991eb;
    transition: .5s;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}

.form-upload-data {
    position: relative;
}

.insert-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.border-icon-upload {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 19px;
    background-color: #cde7fd;
}
.border-icon-upload-previews {
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 19px;
    background-color: #e5e5e5;
}

.info-file {
    word-wrap: break-word;
}

.info-file-mobile {
    max-width: 250px;
    word-wrap: break-word;
    text-align: center;
}

.upload-item__progress-bar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background-color: #bdbdbd;
}

.upload-item__progress-bar-status {
    width: 100%;
    height: 100%;
    background-color: #1991eb;
}

.border-icon-upload.active {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 19px;
    background-color: #cde7fd;
    border: 2px solid #1585d8;
}

.color-icon-upload-customer {
    color: #7f8fa4;
    font-size: 2.5rem;
}

.text-upload-customer {
    padding-top: 10px;
    text-align: center;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    color: #7f8fa4;
}

.files-views ul {
    list-style: none;
}

.border-btn-status-group {
    height: 44px;
    border-radius: 5px;
}

.border-btn-status-active {
    border: 1px solid #2B91FF;
    height: 44px;
    border-radius: 5px;
    color: #007BFF
}


.btn-active {
    background-color: #2B91FF!important;
    color: #ffffff !important;
}

.border-width-each-status {
    border: 1px solid #2B91FF;
    height: 44px;
    width: 100%;
    text-align: center;
    padding-top: 9px;
    background-color: #ffffff;
    color: #007BFF
}

.border-width-each-status-center {
    border-top: 1px solid #2B91FF;
    border-bottom: 1px solid #2B91FF;
    height: 44px;
    width: 100%;
    text-align: center;
    padding-top: 9px;
    background-color: #ffffff;
    color: #007BFF
}

.first-btn {
    border-radius: 5px 0 0 5px;
}

.third-btn {
    border-radius: 0 5px 5px 0;
}

.text-span-selected {
    padding-top: 8px;
    font-size: 18px;
    color: #757575;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}
.col-md-4-custom {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.font-customer-name {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 18px;
    color : #007BFF
}

.font-time-records {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color: #202528;
}

.font-highlight-keyword{
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color: #7F8FA4;
}

.btn-group-select {
    padding-left: 15px;
}



@media only screen and (max-width: 841px) {
    .btn-group-select {
        /*display: none;*/
    }
}

@media only screen and (min-width: 800px) {
    .btn-group-select {
        display: flex!important;
    }
    .pt-30px-important {
        padding-top: 30px !important;
    }
}

@media only screen and (min-width: 1132px) {
    .col-md-4-custom {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .pt-30px-important {
        padding-top: 37px !important;
    }
}

@media only screen and (max-width: 1132px) {
    .col-md-4-custom {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.font-size-18 {
    font-size: 18px;
}


.group-batch-action {
    font-size: 16px;
    color: #007BFF !important;
}

.checkbox-weight > div > .check-group > .checkmark {
    width: 31px;
    height: 31px;
    background-color: #fcfcfc;
    border: 1px solid #707070;

}

.checkbox-weight > div > .check-group .checkmark:after {
    left: 9px;
    font-size: 29px;
    top: 0;
    width: 13px;
    height: 23px;
}

.th-date-batch-action-record-beta {
    color: #757575;
    font-size: 14px;
    cursor: pointer;
}

.checkbox-bigger > div > .check-group > .checkmark {
    width: 31px;
    height: 31px;
}

.checkbox-bigger > div > .check-group .checkmark:after {
    left: 9px;
    font-size: 29px;
    top: 0;
    width: 13px;
    height: 23px;
}
.checkbox-bigger > div > .content-checkbox {
    margin-left: 19px;
    margin-top: 2px;
    margin-bottom: 0;
    font-size: 18px;
}

.border-bottom {
    border-bottom: 1px solid #F2F3F3;
}

.font-customer-name .label-record-beta .font-label-draft {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
}

.border-back-btn {
    border: 1px solid #007BFF;
    height: 44px;
    border-radius: 5px;
    background-color: #ffffff;
    width: 88px;
}

.font-back-label {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
    color: #2B91FF;
}

.font-customer-name-detail {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    font-size: 20px;
    /*font-weight: 500;*/
    color : #007BFF;
}

.navbar-tab-records {
    border-bottom: 1px solid rgb(223, 227, 233);
    background-color: rgb(255, 255, 255);
}

.records-detail .nav-bar.active {
    border-bottom: 2px solid #4291E4 !important;
    color: #4592E4;
    width: 90px;
}

.records-detail {
    text-align: center;
}


.none-border-question {
    border: none !important;
}

.content-question-type {
    padding: 1px 1px 8px 1px;
}

.font-heading-questions {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    color: #0E1D3F;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 1px 1px 16px;
}

.font-content-question {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
    color: #6E798D;
}

.text-form-area {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #0E1D3F;
}
.modal-select-record-beta > div > .modal-kiroku > .modal-dialog {
    max-width: 400px;
    align-items: normal;
}

.padding-content-question {
    padding: 0 0 0 16px;
}

.answer-question.size-select-question .custom-control-label::after,
.answer-question.size-select-question .custom-control-label::before{
    width: 1.25rem;
    height: 1.25rem;
}

.padding-16-answer-question {
    padding-left: 16px !important;
}

.padding-responsive-detail-record {
    padding-left: 20% ;
}

@media only screen and (max-width: 375px) {
    .padding-responsive-detail-record {
        padding-left: 9% ;
    }
}

@media only screen and (max-width: 320px) {
    .padding-responsive-detail-record {
        padding-left: 4% ;
    }
}

.font-text-in-checkbox {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 18px;
    padding-left: 8px;
    color: #202528;
}

.font-text-reset {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
}

.font-text-on-btn {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
}
.nav-bar .badge {
    position: absolute;
    border-radius: 50%;
    background-color: #707070;
    color: white;
}

.fixed-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 900;
    overflow: hidden;
    outline: 0;
}
#padding-checkbox-card-list .customize-media.media.media-single{
    padding: 4px 0 4px 0;
}
.pb-5-customer-selected {
    padding-bottom: 5rem!important;
}
.kiroku-section-drop-down-show-more-scroll > div > div >.css-10nd86i > .css-2o5izw > .css-1hwfws3{
    max-height: 200px;
    overflow: scroll;
}
.fix-icon-search > form > div > .action-input {
    top: 9px
}

.font-text-not-found {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #6E798D;
    text-align: center;
}

.fix-height-date-range > .date-time-range #fix-date-rage .DateRangePicker {
    width: 100%;
}
.fix-height-date-range > .date-time-range #fix-date-rage .DateInput_input.DateInput_input_1 {
    border-bottom: none !important;
    padding: 8px 0 0 1rem;
}
.fix-height-date-range > .date-time-range #fix-date-rage .DateRangePickerInput__withBorder {
    height: 42px !important;
    justify-content: space-evenly;
}

.placeholder-beta-color-757575 > form > div > ::placeholder {
    color: #757575;
}

.label-drafted-at .badge-kiroku {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    font-weight: 200;
    /*font-style: normal;*/
    font-size: 14px;
}

.font-text-records-table {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 20px;
}

.font-text-date-time-records-table {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 20px;
    color: #202528 !important;
}

.font-reset-user-changed {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
}
.align-self-center {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #ffffff;
}

.btn-cancel-on-modal {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    color: #309CF7;
    background-color: #ffffff;
    border-radius: 4px ;
    border: solid 1px #309cf7;
    min-width: 124px;
}

.btn-confirm-on-modal {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #ffffff;
    background-color: #309CF7;
    min-width: 124px;
}

.font-header-on-modal {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    font-weight: 500;
    /*font-style: normal;*/
    font-size: 20px;
    color: #0E1D3F;
}
.set-height-42 #multi-select > div > .css-10nd86i > .css-vj8t7z{
    min-height: 42px;
}
.set-height-42 #multi-select > div > .css-10nd86i > .css-2o5izw {
    min-height: 42px;
}

.color-dropdown-select-pagination {
    color: #757575;
}
.label-record-beta .label-name{
    font-size: 18px !important;
    margin: 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif !important;
    /*font-weight: 500 !important;*/
    /*font-style: normal;*/
}

.background-highText-record-beta {
    background-color: #f4ff3e;
}

.css-input-medium {
    font-size: 16px;
    height: 42px;
}
.font-name-san {
    font-size: 16px;
}

.label-name {
    font-size: 18px;
}
.font-customer-name-detail .font-name-san {
    font-size: 20px !important;
    /*font-weight: 500 !important;*/
    margin: 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    color: #007bff;
}
.font-customer-name-detail .label-name {
    font-size: 20px !important;
    /*font-weight: 500 !important;*/
    margin: 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-style: normal;*/
    color: #007bff;
}

.content-checkbox-beta {
    font-size: 18px;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    color: #0E1D3F
}

.title-date-time {
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 18px;
}

.custom-clear-date #fix-date-rage .DateRangePickerInput_clearDates{
    padding-top: 3px;
}
.batch-action {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    margin-bottom: 10px;
}

.text-customer-selected {
    padding: 8px 0 0 0;
    font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
    /*font-weight: 700;*/
    /*font-style: normal;*/
    font-size: 18px;
    color : #757575
}
.btn-deActive {
    border: 1px solid #007BFF;
    color: #007bff;
    background-color: #FFFF;
}

.font-text-card-detail {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 14px;
    color: #202528;
}

.font-clear-condition-btn {
    font-size: 16px;
    color: #007BFF;
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.re-native-link:hover {
    cursor: pointer;
    color: #0056B3;
}

.column-draft {
    min-width: 100px;
    padding-left: 10px;
}

.column-template {
    width: 100%;
    min-width: 237px;
    padding-left: 10px;
}

.column-customer-name {
    min-width: 142px;
    width: 238px;
    padding-left: 10px;
}

.column-updater {
    min-width: 142px;
    padding-left: 10px;
}

.column-service-at {
    min-width: 211px;
    padding-left: 10px;
}

.column-update-at {

    min-width: 211px;
    padding-left: 10px;
}

.max-width-table-record-beta {
    width: 100%;
    min-width: 1064px;
}

.header-table-record-beta {
    color: #757575;
    font-size: 14px;
}
.border-delete-btn {
    border: 1px solid red;
    height: 44px;
    border-radius: 5px;
    background-color: #ffffff;
    width: 88px;
}
.font-delete-label {
    font-family: Noto Sans JP, NotoSansCJKjp-Medium,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
    font-size: 16px;
    color: red;
}

.scroll-question-content {
    overflow: scroll;
    height: 300px;
}

.modal-select-record-beta > div > .modal-kiroku > .modal-dialog > .modal-content{
    margin-bottom: 32px;
}

.about-record-detail-beta {
    position: sticky;
    z-index: 997;
}

.avatar-form-comment {
    float: left;
}

.input-form-comment {
    width: 100%;
    z-index: 2;
}
.form-comment {
    position: sticky;
    bottom: 0;
    background-color: white;
}

.border-read-record {
    border-left: 4px solid #2B91FF;
}
.link-action-modal-show-difference-question {
    cursor: pointer;
    color: #007bff;
}
.width-difference-question {
    width: 100%;
}

.font-text-in-batch-action {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.font-text-card-btn {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

.font-text-record-card {
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    /*font-weight: 500;*/
    /*font-style: normal;*/
}

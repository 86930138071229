.page-error {
    height: 100%;
    z-index: 9999999;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

.wrapper-page-error {
    position: relative;
    width: 100%;
    height: 100%;
}

.policy-page {
    padding: 2cm 2cm 2cm 3cm;
}

.policy-page p {
    text-align: justify;
    text-indent: 30px;
    word-break: break-all;
    font-size: 14px;
}
.policy-page .number-list {
    counter-reset: list;
    padding: 0 0 0 5px;
}
.policy-page .number-list li {
    list-style: none;
    text-align: justify;
}
.policy-page .number-list li:before {
    content: "(" counter(list) ")";
    counter-increment: list;
    text-align: justify;
}

.table-privacy {
    padding: 0 50px 0 50px
}

.table-privacy table tr td {
    word-break: break-all;
}


@media only screen and (max-width: 700px) {
    .policy-page {
        padding: 2cm 1cm 2cm 1cm !important;
    }

    .table-privacy {
        padding: 0 !important;
    }

}
.A4 {
    background: #FFFFFF;
    width: 21cm;
    display: block;
    padding: 10px 25px;
    margin: 0 auto 0.5cm;
    /*box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);*/
    box-sizing: border-box;
    font-family: "MS PGothic", sans-serif;
    font-size: 12px;
}

.policy-page div {
    font-size: 14px;
}

.policy-page ol li {
    font-size: 14px;
}

.policy-page ul li {
    font-size: 14px;
}

.text-align {
    text-align: justify;
}

.font-weight-header {
    font-weight: 700
}

.align-header {
    text-align: center;
}

.width-table-privacy {
    width: 50%
}
.align-content {
    text-align: justify;
    text-indent: 39px;
    word-break: break-all;
}
.font-size-content {
    font-size: 14px;

}

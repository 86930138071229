.card-dashboard {
    border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 19px 0 rgba(197, 197, 197, 0.24);
    background-color: #ffffff;
}

.title-card-dashboard {
    font-size: 14px;
    color: #091e42;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 400;
    /*font-style: normal;*/
}

.number-card-dashboard {
    font-size: 32px;
    color: #091e42;
}

.recent-title {
    font-size: 24px;
    color: #091e42;
    font-family: Noto Sans JP, NotoSansCJKjp-Regular,sans-serif;
    font-weight: 300;
    /*font-style: normal;*/
}

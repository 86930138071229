@media only screen and (max-width: 325px) {
    .staff-detail .nav-bar {
        font-size: 12px;
        font-weight: 500;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }

}


@media only screen and (min-width: 326px) {
    .staff-detail .nav-bar {
        font-size: 13px;
        font-family: Noto Sans JP, NotoSansCJKjp-Bold,sans-serif;
        font-weight: 500;
        /*font-style: normal;*/
        border: solid 1px transparent;
        padding: 7px 0;
        color: #6c798f;
    }
}

.staff-detail .nav-bar.active {
    border-bottom: 2px solid #1991eb !important;
    color: #1991eb;
}

.staff-detail .avatar-user-detail {
    width: 120px;
    height: 120px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
}

.staff-detail .height-avatar {
    height: 98px;
}
